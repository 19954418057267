import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <footer className="footer">
            <div className="container">
                <div className="footer_box">
                    <Link className="footer_item" to="/oferta">
                        Оферта и политика <br />
                        конфиденциальности
                    </Link>
                    <div className="footer_item">Все права защищены</div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
