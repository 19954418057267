import { Checkbox, FormControlLabel } from '@mui/material';
import Menu from 'components/menu';
import PositionedSnackbar from 'components/snackbar/snackbar';
import React, { useState } from 'react';
import { useParams } from 'react-router';
import { ordersAPI } from 'api';
import { useAsync, useAsyncFn } from 'react-use';
import LoadingOverlay from 'react-loading-overlay';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { checkCode } from 'api/orders';
import Footer from 'components/footer';

const Prizes = () => {
    const { orderId } = useParams();
    const prizes_other = useSelector((state) => state.state.prizes);
    const prizes_msk = useSelector((state) => state.state.prizes_msk);
    const [city, setCity] = useState(false);

    const prizes = city === 'OTHER' ? prizes_other : prizes_msk;

    const [scratchCardsCount, setScratchCardsCount] = useState(0);

    const [checked, setChecked] = useState(false);
    const [checkedBorder, setCheckedBorder] = useState(false);

    const [currentPriz, setCurrentPriz] = useState(null);
    const [allPrizes, setAllPrizes] = useState([]);

    const [popUpPriz, setPopUpPriz] = useState(null);

    const [error, setError] = useState(null);
    const [successGive, setSuccessGive] = useState(false);

    const winnerPrizes = () => {
        const numbers = prizes.map((priz) => ({
            ...priz,
            numbers_win: Array.apply(0, Array(2000)).map((_, idx) => priz.startNumber + priz.step * idx)
        }));
        return Array.apply(0, Array(2000)).map((_, idx) => {
            let win = {};
            numbers.forEach((item) => {
                if (item.hardNumber !== null && item.hardNumber.includes(idx + 1)) {
                    if (win.id != null) return;
                    win = item;
                } else if (item.numbers_win.includes(idx + 1)) {
                    if (win.id != null) return;
                    win = item;
                }
            });
            return win;
        });
    };

    const prizesData = winnerPrizes();
    const getOrderState = useAsync(async () => {
        const response = await checkCode({ code: `lzet-${orderId}`, full: true });
        if (response.ok) {
            const orderJson = await response.json();
            setCity(orderJson.city);
            if (orderJson.isUse || orderJson.prize) {
                setError('PRIZE_ALREADY_GIVE');
            } else {
                setScratchCardsCount(orderJson.prizeCode ? 0 : 1);
            }
        } else {
            setError('NOT_FOUND');
        }
    }, []);

    const state = useAsync(async () => {
        try {
            const sentedPrize = allPrizes.map((prize) => ({
                productName: prize.title,
                quantity: 1,
                initialPrice: 0,
                id: prize.id
            }));
            if (!sentedPrize[0]) return;
            await ordersAPI.updateOrderItems({
                code: `lzet-${orderId}`,
                prize: sentedPrize[0].productName,
                prizeCode: sentedPrize[0].id
            });
        } catch (err) {
            Object.entries(err).forEach(([key, value]) => {
                console.log(key, value);
            });
        }
    }, [allPrizes]);

    const handleOpenPopUpCart = (priz) => {
        setPopUpPriz(priz);
    };

    const [, handleOpenCart] = useAsyncFn(
        async (idx) => {
            const response = await ordersAPI.getNumberPP({ code: `lzet-${orderId}` });
            const numberPPjson = await response.json();
            if (checked) {
                setAllPrizes([...allPrizes, prizesData[numberPPjson]]);
                setCurrentPriz({ idx, priz: prizesData[numberPPjson] });
                setScratchCardsCount(scratchCardsCount - 1);
                setCheckedBorder(false);
            } else {
                setCheckedBorder(true);
            }
        },
        [orderId, checked, prizesData]
    );

    function decodeErrorMsg(error) {
        switch (error) {
            case 'NOT_FOUND':
                return 'Код не найден';
            case 'PRIZE_ALREADY_GIVE':
                return 'Приз уже добавлен!';
            default:
                return '';
        }
    }

    if (successGive) {
        return (
            <div className="rellbox overflow body on_thank" style={{ width: '100vw', height: '100vh' }}>
                <Menu />
                <div className="empty_header" />
                <img src="/media/6221f145905f0017c860bfc5_Freesia20Flower.G05201.png" loading="lazy" alt="" className="thank_flover" />
                <div className="wrapper owerflow for_thank" style={{ display: 'flex', flexDirection: 'column', alignItems: 'stretch' }}>
                    <section className="section about">
                        <div className="container">
                            <div className="thank_content">
                                <div className="thank_subtitle">Скретч-карта</div>
                                <div className="thank_desc">
                                    <div>
                                        <strong>Ваш подарок успешно добавлен!</strong>
                                    </div>
                                    <div>
                                        <Link to="/">
                                            <a>Вернуться</a>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <div style={{ position: 'fixed', bottom: 0, width: '100vw' }}>
                        <PositionedSnackbar />
                    </div>
                </div>
            </div>
        );
    }

    if (error != null) {
        return (
            <div className="rellbox overflow body on_thank" style={{ width: '100vw', height: '100vh' }}>
                <Menu />
                <div className="empty_header" />
                <img src="/media/6221f145905f0017c860bfc5_Freesia20Flower.G05201.png" loading="lazy" alt="" className="thank_flover" />
                <div className="wrapper owerflow for_thank" style={{ display: 'flex', flexDirection: 'column', alignItems: 'stretch' }}>
                    <section className="section about">
                        <div className="container">
                            <div className="thank_content">
                                <div className="thank_subtitle">Скретч-карта</div>
                                <div className="thank_desc">
                                    <div>
                                        <strong>{`${decodeErrorMsg(error)}`}</strong>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <div style={{ position: 'fixed', bottom: 0, width: '100vw' }}>
                        <PositionedSnackbar />
                    </div>
                </div>
            </div>
        );
    }

    return (
        <LoadingOverlay active={state.loading || getOrderState.loading} spinner>
            <div className="rellbox overflow body on_thank" style={{ minWidth: '100vw', minHeight: '100vh' }}>
                <img src="/media_new/logo-01.svg" loading="lazy" width="100px" alt="" className="thank_bg_image" />
                <Menu />
                <div className="empty_header" />
                <img src="/media/6221f145905f0017c860bfc5_Freesia20Flower.G05201.png" loading="lazy" alt="" className="thank_flover" />
                <div className="wrapper owerflow for_thank" style={{ display: 'flex', flexDirection: 'column', alignItems: 'stretch' }}>
                    <section className="section about">
                        <div className="container">
                            <div className="thank_content">
                                <div className="thank_subtitle">Скретч-карта</div>
                                <div className="thank_desc">
                                    <div>
                                        <br />
                                        Вам доступны:
                                        <span data-content="prize_count_text" className="card_info">
                                            {scratchCardsCount} {scratchCardsCount === 0 ? 'скретч-карт' : 'скретч-карта'}
                                        </span>
                                    </div>
                                    <div>Выбери скретч-карту и получи приз!</div>
                                </div>
                            </div>
                            <div className="w-form">
                                <div className={checkedBorder ? 'w-checkbox prize-soglasie politics_border' : 'w-checkbox prize-soglasie'}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={checked}
                                                onChange={(event) => setChecked(event.target.checked)}
                                                name="checked"
                                                color="primary"
                                            />
                                        }
                                        label="Я ознакомлен и согласен с условиями продажи Товаров и даю согласие на обработку моих персональных
                                            данных в соответствии с политикой обработки персональных данных"
                                    />
                                </div>
                            </div>
                            {allPrizes.length > 0 && (
                                <div>
                                    <div data-content="prize_box" className="prize-box w-clearfix">
                                        <div className="prize_info">
                                            Ваш приз:
                                            <br />
                                            <span className="text-span-3">(нажми на приз для просмотра)</span>
                                        </div>
                                        {allPrizes.map((priz, idx) => (
                                            <div
                                                key={idx}
                                                role="button"
                                                tabIndex={0}
                                                onClick={() => handleOpenPopUpCart(priz)}
                                                onKeyDown={() => handleOpenPopUpCart(priz)}
                                                className="selected-prizes"
                                            >
                                                <div className="button prize_button w-button">{priz?.title}</div>
                                            </div>
                                        ))}
                                    </div>
                                    <div style={{ width: '50%', margin: 'auto', textAlign: 'center', color: 'red' }}>
                                        <span data-content="prize_count_text" className="card_info" style={{ color: 'red' }}>
                                            В ближайшее время с Вами свяжется наш менеджер по вопросу получения подарка. Если вдруг с Вами
                                            не связались в течение 3 рабочих дней, напишите нам в директ @lzet.brand (где вы получили свой
                                            промокод)
                                        </span>
                                    </div>
                                    {/*  <div style={{ width: '50%', margin: 'auto', textAlign: 'center', color: 'red' }}>
                                        <span data-content="prize_count_text" className="card_info" style={{ color: 'red' }}>
                                            В ближайшее время с Вами свяжется наш менеджер по вопросу получения подарка. Если вдруг с Вами
                                            не связались в течение 3 рабочих дней, свяжитесь с нами через Instagram - директ @lzet.brand
                                            (где вы получили свой промокод)
                                        </span>
                                    </div> */}
                                </div>
                            )}
                            {scratchCardsCount > 0 && (
                                <div className="thank_items">
                                    {prizes.map((priz, idx) => (
                                        <div
                                            key={priz.id}
                                            role="button"
                                            tabIndex={0}
                                            onClick={() => (scratchCardsCount > 0 ? handleOpenCart(idx) : undefined)}
                                            onKeyDown={() => (scratchCardsCount > 0 ? handleOpenCart(idx) : undefined)}
                                            className="thank_item"
                                        >
                                            {(currentPriz?.idx ?? 99) !== idx ? (
                                                <div
                                                    className="front"
                                                    style={{
                                                        backgroundImage: `url(/media_new/card.png)`
                                                    }}
                                                />
                                            ) : (
                                                <div
                                                    key={priz.id}
                                                    data-bg={currentPriz.priz.imageUrl}
                                                    className="back"
                                                    style={{
                                                        backgroundImage: `url(${currentPriz.priz.imageUrl})`
                                                    }}
                                                />
                                            )}
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    </section>

                    {popUpPriz && (
                        <div className="win_popup">
                            <div className="win_popup_close" />
                            <div className="win_popup_window">
                                <div
                                    role="button"
                                    tabIndex={-1}
                                    onClick={() => handleOpenPopUpCart(null)}
                                    onKeyDown={() => handleOpenPopUpCart(null)}
                                    className="close_ico for_win"
                                >
                                    <img src="/media/61c99fc18a62bb04e0602923_Close.svg" loading="lazy" alt="" className="for_win" />
                                </div>
                                <div className="win_popup_title">ВАШ ПРИЗ</div>
                                <div className="win_subtitle_box">
                                    <div data-content="prize_text" className="prize-desc">
                                        {popUpPriz.title}
                                        <br />
                                        {popUpPriz.desc}
                                        <br />
                                        {popUpPriz.link && 'Пройдите по ссылке'}
                                        <br />
                                        {popUpPriz.link && <a href={popUpPriz.link}>{popUpPriz.link}</a>}
                                    </div>
                                </div>
                                <img
                                    src={popUpPriz.imageUrl}
                                    loading="lazy"
                                    data-content="prize_image"
                                    alt=""
                                    className="win_product_image"
                                />
                                {/* <img src="/media/61c99f5b612531d4e56f76dd_Rectangled.png" loading="lazy" alt="" className="win_bg_img" /> */}
                                <img
                                    src="/media/61c99f5cc7e9a9580da3cde4_D0BAD0BED0BDD184D0B5D182D182D0B82.png"
                                    loading="lazy"
                                    alt=""
                                    className="win_popup_conf"
                                />
                            </div>
                        </div>
                    )}
                    <Footer />
                    <PositionedSnackbar />
                </div>
            </div>
        </LoadingOverlay>
    );
};

export default Prizes;
