import config from 'config';

export const prizes_msk = [
    {
        id: 13,
        title: 'Флюид + Крем для ног',
        type: 'easy',
        imageUrl: `/media_new/krem.png`,
        desc: 'Поздравляем! Вы выиграли комплект "Флюид и крем для ног". 🥳 В ближайшее время с Вами свяжется наш менеджер для уточнения получения подарка!',
        link: null /* 'https://millidge.ru/horoscope' */,
        cardImage: `${config.storeUrl}/Novyi-proekt-5.png`,
        startNumber: 678678678678,
        step: 678678678678,
        priority: 1,
        hardNumber: [
            1, 7, 13, 19, 25, 31, 37, 43, 49, 55, 61, 67, 73, 79, 85, 91, 97, 103, 109, 115, 121, 127, 133, 139, 145, 151, 157, 163, 169,
            175, 181, 187, 193, 199, 205, 211, 217, 223, 229, 235, 241, 247, 253, 259, 265, 271, 277, 283, 289, 295
        ]
    },
    {
        id: 14,
        title: 'Кокосовый скраб',
        type: 'easy',
        imageUrl: `/media_new/scrub.png`,
        desc: 'Поздравляем! Вы выиграли "Кокосовый скраб". 🥳 В ближайшее время с Вами свяжется наш менеджер для уточнения получения подарка!',
        link: null /* `https://1790009369.rsc.cdn77.org/millidge-common/anti.pdf` */,
        cardImage: `${config.storeUrl}/Novyi-proekt-5.png`,
        startNumber: 999999999999999,
        step: 99999999999,
        priority: 1,
        hardNumber: [
            2, 8, 14, 20, 26, 32, 38, 44, 50, 56, 62, 68, 74, 80, 86, 92, 98, 104, 110, 116, 122, 128, 134, 140, 146, 152, 158, 164, 170,
            176, 182, 188, 194, 200, 206, 212, 218, 224, 230, 236, 242, 248, 254, 260, 266, 272, 278, 284, 290, 296
        ]
    },
    {
        id: 15,
        title: 'Сыворотка',
        type: 'medium',
        imageUrl: `/media_new/elixir.png`,
        desc: 'Поздравляем, вы выиграли "Сыворотку", 🥳 В ближайшее время с Вами свяжется наш менеджер для уточнения получения подарка!',
        link: null,
        cardImage: `${config.storeUrl}/Novyi-proekt-12.png`,
        productId: 7741,
        startNumber: 99999999,
        step: 9999999999,
        priority: 1,
        hardNumber: [
            3, 9, 15, 21, 27, 33, 39, 45, 51, 57, 63, 69, 75, 81, 87, 93, 99, 105, 111, 117, 123, 129, 135, 141, 147, 153, 159, 165, 171,
            177, 183, 189, 195, 201, 207, 213, 219, 225, 231, 237, 243, 249, 255, 261, 267, 273, 279, 285, 291, 297
        ]
    },
    {
        id: 16,
        title: 'Гайд',
        type: 'medium',
        imageUrl: `/media_new/guide.png`,
        desc: 'Поздравляем, вы открыли доступ к Гайду по стилю, 🥳 Скачать гайд можно ниже по ссылке!',
        link: 'https://lzet-gift.ru/guide.pdf',
        cardImage: `${config.storeUrl}/Novyi-proekt-12.png`,
        productId: 585,
        startNumber: 207897897897891,
        step: 1,
        priority: 1,
        hardNumber: [
            4, 10, 16, 22, 28, 34, 40, 46, 52, 58, 64, 70, 76, 82, 88, 94, 100, 106, 112, 118, 124, 130, 136, 142, 148, 154, 160, 166, 172,
            178, 184, 190, 196, 202, 208, 214, 220, 226, 232, 238, 244, 250, 256, 262, 268, 274, 280, 286, 292, 298
        ]
    },
    {
        id: 17,
        title: 'Гайд',
        type: 'medium',
        imageUrl: `/media_new/guide.png`,
        desc: 'Поздравляем, вы открыли доступ к Гайду по стилю, 🥳 Скачать гайд можно ниже по ссылке!',
        link: 'https://lzet-gift.ru/guide.pdf',
        cardImage: `${config.storeUrl}/Novyi-proekt-12.png`,
        productId: 585,
        startNumber: 207897897897891,
        step: 1,
        priority: 1,
        hardNumber: [
            5, 11, 17, 23, 29, 35, 41, 47, 53, 59, 65, 71, 77, 83, 89, 95, 101, 107, 113, 119, 125, 131, 137, 143, 149, 155, 161, 167, 173,
            179, 185, 191, 197, 203, 209, 215, 221, 227, 233, 239, 245, 251, 257, 263, 269, 275, 281, 287, 293, 299, 6, 12, 18, 24, 30, 36,
            42, 48, 54, 60, 66, 72, 78, 84, 90, 96, 102, 108, 114, 120, 126, 132, 138, 144, 150, 156, 162, 168, 174, 180, 186, 192, 198,
            204, 210, 216, 222, 228, 234, 240, 246, 252, 258, 264, 270, 276, 282, 288, 294, 300
        ]
    },
    {
        id: 18,
        title: 'Гайд',
        type: 'medium',
        imageUrl: `/media_new/guide.png`,
        desc: 'Поздравляем, вы открыли доступ к Гайду по стилю, 🥳 Скачать гайд можно ниже по ссылке!',
        link: 'https://lzet-gift.ru/guide.pdf',
        cardImage: `${config.storeUrl}/Novyi-proekt-12.png`,
        productId: 585,
        startNumber: 301,
        step: 1,
        priority: 1,
        hardNumber: null
    },

    {
        id: 19,
        title: 'Гайд',
        type: 'hard',
        imageUrl: `${config.storeUrl}/iphone-12-1-300x300.png`,
        desc: 'Поздравляем, вы открыли доступ к Гайду по стилю, 🥳 Скачать гайд можно ниже по ссылке!',
        link: null,
        cardImage: `${config.storeUrl}/Novyi-proekt-12.png`,
        productId: 'iphone',
        startNumber: 990099000,
        step: 1000990000,
        priority: 1,
        hardNumber: null
    },
    {
        id: 20,
        title: 'Гайд',
        type: 'hard',
        imageUrl: `/media_new/guide.png`,
        desc: 'Поздравляем, вы открыли доступ к Гайду по стилю, 🥳 Скачать гайд можно ниже по ссылке!',
        link: null,
        cardImage: `${config.storeUrl}/Novyi-proekt-9.png`,
        productId: '1000',
        startNumber: 999999999999,
        step: 100099999000,
        priority: 1,
        hardNumber: null
    },
    {
        id: 21,
        title: 'Гайд',
        type: 'easy',
        imageUrl: `/media_new/guide.png`,
        desc: 'Поздравляем, вы открыли доступ к Гайду по стилю, 🥳 Скачать гайд можно ниже по ссылке!',
        link: 'https://lk.investor-plus.com/register-member-free?type=millidge',
        cardImage: `${config.storeUrl}/Novyi-proekt-11.png`,
        productId: 'investor',
        startNumber: 999999999999,
        step: 999999999999,
        priority: 1,
        hardNumber: null
    },
    {
        id: 22,
        title: 'Гайд',
        type: 'medium',
        imageUrl: `/media_new/guide.png`,
        desc: 'Поздравляем, вы открыли доступ к Гайду по стилю, 🥳 Скачать гайд можно ниже по ссылке!',
        link: null,
        cardImage: `${config.storeUrl}/Novyi-proekt-11.png`,
        productId: '2000',
        startNumber: 99999999999,
        step: 399999999,
        priority: 1,
        hardNumber: null
    },
    {
        id: 23,
        title: 'Гайд',
        type: 'medium',
        imageUrl: `/media_new/guide.png`,
        desc: 'Поздравляем, вы открыли доступ к Гайду по стилю, 🥳 Скачать гайд можно ниже по ссылке!',
        link: null,
        cardImage: `${config.storeUrl}/Novyi-proekt-12.png`,
        productId: '5000',
        startNumber: 99999999,
        step: 21111111111,
        priority: 1,
        hardNumber: null
    },
    {
        id: 24,
        title: 'Гайд',
        type: 'medium',
        imageUrl: `${config.storeUrl}/5000.jpg`,
        desc: 'Поздравляем, вы открыли доступ к Гайду по стилю, 🥳 Скачать гайд можно ниже по ссылке!',
        link: null,
        cardImage: `${config.storeUrl}/Novyi-proekt-12.png`,
        productId: '5000',
        startNumber: 99999999,
        step: 21111111111,
        priority: 1,
        hardNumber: null
    }
];
