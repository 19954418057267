import React from 'react';

const Oferta = () => {
    return (
        <div>
            {/* <Header /> */}
            <div className="wrapper owerflow">
                <section className="section  faq">
                    <div className="container">
                        <h1 className="page_title" style={{ marginTop: '36px' }}>
                            Оферта
                        </h1>

                        <div className="faq_box">
                            <div className="content_box w-richtext">
                                <p>
                                    <b>Договор-оферта о проведении розыгрыша</b>
                                </p>
                                <p>&nbsp;</p>
                                <p>
                                    <p>1. ОБЩИЕ ПОЛОЖЕНИЯ</p>
                                    <p>
                                        1.1. Акция «Розыгрыш подарков (далее - «Акция») является маркетинговой акцией, представляющей собой
                                        комплекс мероприятий, задачей которых является популяризация компании L`zet. Розыгрыш не является
                                        лотереей, не содержит элемента риска, не преследует цели получения Организатором прибыли либо иного
                                        дохода, не обусловленного увеличением продаж продукции, и проводится в соответствии с настоящими
                                        условиями (далее - «Правила»). Право на участие в Акции не связано с внесением платы за такое
                                        участие, в том числе, за пользование Интернетом, призовой фонд Акции не формируется за счет оплаты
                                        Участниками, в том числе, Интернета.
                                    </p>
                                    <p>1.2. Территорией проведения Акции является территория Российской Федерации.</p>
                                    <p>2. ОПРЕДЕЛЕНИЯ, СОДЕРЖАЩИЕСЯ В ПРАВИЛАХ</p>
                                    <p>
                                        2.1. Уникальный код - персональный номер, уникальный для каждого пользователя, участвующего в Акции.
                                    </p>
                                    <p>
                                        2.2. Участник – лицо, отвечающее требованиям. Указанным в Разделе 4 настоящих Правил и принимающее
                                        участие в Акции (осуществляющее действия, предусмотренные настоящими Правилами).
                                    </p>
                                    <p>3. ОРГАНИЗАТОР АКЦИИ</p>
                                    <p>
                                        3.1. Организатор Акции является: ООО «ТЦ НЕЙРОСТИЛЬ, Юридический адрес: 358000, РОССИЯ, РЕСП
                                        КАЛМЫКИЯ, Г ЭЛИСТА, УЛ ИМ ГУБАРЕВИЧА, Д 5, КВ ОФИС 312А, ИНН 0800007679, КПП 080001001, ОГРН
                                        1230800002047, ОКПО 79830466, Расчетный счет: 40702810810001378370, Филиал АО {`'ТИНЬКОФФ БАНК'`},
                                        ИНН банка: 7710140679, БИК банка: 044525974, Корреспондентский счет банка: 30101810145250000974,
                                        Юридический адрес банка: Москва, 127287, ул. Хуторская 2-я, д. 38А, стр. 26 490-77-77.
                                    </p>
                                    <p>4. УЧАСТНИКИ АКЦИИ</p>
                                    <p>
                                        4.1. К участию в Акции допускаются дееспособные лица-граждане Российской Федерации, достигшие
                                        18-летнего возраста, постоянно проживающие на территории Российской Федерации.
                                    </p>
                                    <p>
                                        4.2. В Акции не имеют права участвовать работники Организатора, члены семей данных работников;
                                        физические лица, с которыми у Организатора заключены гражданско-правовые договоры; сотрудники
                                        организаций, привлекаемых Организатором к проведению Акции, а также члены их семей.
                                    </p>
                                    <p>5. СРОКИ ПРОВЕДЕНИЯ АКЦИИ</p>
                                    <p>5.1. Общие сроки проведения Акции: с 21 июля 2023 года по 04 августа 2023 года.</p>
                                    <p>
                                        5.2. Срок регистрации Участниками: с 12 часов 00 минут первого дня текущей акции и до 23 часов 59
                                        минут последнего дня текущей акции.
                                    </p>
                                    <p>
                                        5.3. Порядок получения выигрыша: Победитель самостоятельно забирает выигрыш по одному из адресов
                                        либо онлайн:
                                        <p>г. Москва, ул. Большая Никитская, д. 21/18, стр. 1</p>
                                        <p>г. Москва, ул. Петровка, д. 26 строение 3</p>
                                        <p>г. Москва, ул. Сайкина, д. 19</p>
                                        <p>г. Санкт-Петербург, ул. Суворовский пр., 20</p>
                                        <p>г. Санкт-Петербург, ул. Большая Посадская, 12</p>
                                        <p>г. Казань, просп. Победы, д. 139 корпус 2</p>
                                        <p>г. Уфа, ул. Достоевского, 106</p>
                                        <p>г. Тверь, ул. Жигарева, д. 7</p>
                                    </p>
                                    <p>5.4. Все сроки, указанные в настоящих Правилах, здесь и далее приведены по московскому времени.</p>
                                    <p>6. ПРИЗОВОЙ ФОНД АКЦИИ</p>
                                    <p>6.1. Призы: Iphone, косметика от партнеров, денежные вознаграждения, гайды</p>
                                    <p>
                                        6.2. Приз является единым (целым), в связи с чем выплата победителю денежной составляющей отдельно
                                        от Приза либо денежного эквивалента Приза, либо его части - не производится.
                                    </p>
                                    <p>
                                        6.2.1. Согласно действующему законодательству Российской Федерации не облагаются налогом на доходы
                                        физических лиц доходы, не превышающие в совокупности 4000,00 руб. (четырех тысяч рублей 00 копеек),
                                        полученные за налоговый период (календарный год) от организаций, в т.ч., в виде подарков, выигрышей
                                        или Призов в проводимых конкурсах, играх и других мероприятиях в целях рекламы товаров (работ,
                                        услуг) (п. 28 ст. 217 НК РФ). 6.2.3. Победители обязаны осуществлять уплату всех налогов и иных
                                        существующих обязательных платежей, связанных с получением Призов, как это установлено действующим
                                        законодательством Российской Федерации. При этом опубликованием настоящих Правил считается, что
                                        Организатор надлежащим образом проинформировал Победителя о законодательно предусмотренной
                                        обязанности уплатить соответствующие налоги в связи с получением такого Приза, совокупная стоимость
                                        которого превышает 4000,00 (Четыре тысячи рублей 00 копеек) за налоговый период (календарный год) и
                                        получил согласие на удержание, как предусмотрено в пункте 6.2.4. настоящих Правил.
                                    </p>
                                    <p>
                                        6.2.2. Организатор рассчитывает НДФЛ и удерживает его из причитающейся денежной части Приза в
                                        соответствии с действующим налоговым законодательством Российской Федерации для перечисления его в
                                        государственный бюджет соответствующего уровня. При этом Участник дает свое согласие, что Оператор
                                        может направить 100% денежной части Приза на уплату налога.
                                    </p>
                                    <p>
                                        6.2.3. Принимая участие в Акции и соглашаясь с настоящими Правилами, Участник считается надлежащим
                                        образом проинформирован о вышеуказанной обязанности.
                                    </p>
                                    <p>7. ПОРЯДОК УЧАСТИЯ В АКЦИИ</p>
                                    <p>
                                        7.1. Для участия в Акции Участнику необходимо в период проведения текущей Акции получить промокод
                                        посредстам покупки товара бренда L`zet` ма маркетплейсе wildberries
                                    </p>
                                    <p>
                                        7.2. Осуществление Участником регистрации\авторизации на Сайте означает предоставление Участником
                                        Организатору и/или Оператору полного согласия Участника с настоящими Правилами, согласие Участника
                                        на получение, хранение и обработку Организатором и/или Оператором его персональных данных на
                                        условиях, указанных в настоящих Правилах, согласие Участника с условиями публичной оферты,
                                        размещенной на Сайте (далее – «Оферта»), согласие участника на получение от Организатора и/или
                                        Оператора информации об Акции либо иной информации (об иных акциях и/или продукции Организатора), а
                                        также означает факт заключения договора о предоставлении информационных услуг.
                                    </p>
                                    <p>
                                        7.3. Организатор Акции вправе безапелляционно в одностороннем порядке принимать решения относительно
                                        соответствия полученных обращений о регистрации Уникальных\Бонусных кодов настоящим Правилам.
                                    </p>
                                    <p>8. СПОСОБ И ПОРЯДОК ИНФОРМИРОВАНИЯ УЧАСТНИКОВ О СРОКАХ И ПРАВИЛАХ ПРОВЕДЕНИЯ АКЦИИ</p>
                                    <p>8.1. Участники информируются о Правилах Акции и сроках ее проведения следующими способами:</p>
                                    <p>8.1.1. Путем размещения кратких условий Акции в Социальных сетях;</p>
                                    <p>9.1. Участники имеют право:</p>
                                    <p>
                                        • знакомиться с Правилами Акции и получать информацию из источников, упомянутых в настоящих
                                        Правилах;
                                    </p>
                                    <p>• принимать участие в Акции в порядке, определенном настоящими Правилами;</p>
                                    <p>
                                        • получать призы при соблюдении соответствующих условий Акции, предусмотренных в настоящих Правилах;
                                    </p>
                                    <p>
                                        • заявить о своем отказе от участия в Акции и/или получения приза. Участник подтверждает, что он
                                        уведомлен и соглашается с тем, что он автоматически утрачивает все свои права на получение приза,
                                        начиная с даты отправки письменного и/или по электронной почте (с адреса, зарегистрированного на
                                        Сайте) уведомления Организатору об отказе от участия в Акции и/или получения приза. Об исключении
                                        Участника из Акции Организатор направляет Участнику уведомление по адресу электронной почты, или по
                                        средствам связи.
                                    </p>
                                    <p>9.2. Участники обязаны:</p>
                                    <p>
                                        • оплачивать все расходы, понесенные в связи с участием в Акции (в том числе, расходы, связанные с
                                        доступом в Интернет, все прочие расходы). Организатор не получает указанные платежи и не формирует
                                        за счет них призовой фонд Акции;
                                    </p>
                                    <p>
                                        9.3. Участники, признанные обладателями Призов для получения Приза обязаны подтвердить владение
                                        номером мобильного телефона, указанного в розыгрыше
                                    </p>
                                    <p>
                                        9.4. Участник, принимая участие в Акции, соглашается с тем, что Организатор оставляет за собой право
                                        отказать Победителю Акции в выдаче приза, если Участником будут нарушены/не соблюдены все условия
                                        настоящих Правил.
                                    </p>
                                    <p>
                                        9.5. Получить Приз в течение 1 календарного месяца после объявления победителя и уведомления
                                        Участника по средствам звонка.
                                    </p>
                                    <p>10. ПРАВА И ОБЯЗАННОСТИ ОРГАНИЗАТОРА</p>
                                    <p>10.1.Организатор имеет право:</p>
                                    <p>10.1.1. Отказать в выдаче приза Участнику, который не выполнил условия участия, указанные в п. 8.</p>
                                    <p>
                                        10.1.2. Не признавать Участниками Акции и отказать в выдаче каких-либо призов лицам, не
                                        соответствующим требованиям, изложенным в Разделе 4 настоящих Правил.
                                    </p>
                                    <p>
                                        10.1.3. Организатор Акции вправе признать недействительными все или любые заявки на участие, а также
                                        запретить дальнейшее участие в настоящей Акции любому лицу, которое подделывает и/или извлекает
                                        выгоду из любой подделки или нарушения процесса регистрации на участие в Акции или же осуществляет
                                        действия с целью оскорбления, угрозы, причинения беспокойства любому иному лицу, которое может быть
                                        связано с настоящей Акцией или любые иные противоправные действия, либо если Участник отрицательно
                                        публично отзывается о компании Организатора, либо участие Участника в Акции несет для Организатора
                                        репутационные риски.
                                    </p>
                                    <p>
                                        10.1.4. Организатор оставляет за собой право не вступать в письменные переговоры либо иные контакты
                                        с Участниками, кроме случаев, предусмотренных настоящими Правилами и действующим законодательством
                                        Российской Федерации.
                                    </p>
                                    <p>
                                        10.1.7. В одностороннем порядке прекратить, изменить или приостановить проведение Акции, если по
                                        какой-то причине любой аспект настоящей Акции не может проводиться так, как это запланировано,
                                        включая причины, вызванные заражением компьютерными вирусами, неполадками в сети мобильной связи,
                                        дефектами, манипуляциями, несанкционированным вмешательством, фальсификацией, техническими
                                        неполадками или любой причиной, неконтролируемой Организатором, которая искажает или затрагивает
                                        исполнение, безопасность, честность, целостность или надлежащее проведение Акции либо если
                                        проведение Акции полностью либо частично будет запрещено уполномоченными государственными органами.
                                    </p>
                                    <p>
                                        10.1.8. Отказать в рассмотрении претензии по неполученным призам в случае, если приз не был
                                        своевременно получен Участником. Приз не может быть востребован Участником повторно. Организатор не
                                        несет ответственности за качество работы и действия третьих лиц, в том числе почтовых служб и
                                        интернет-провайдеров.
                                    </p>
                                    <p>
                                        10.2.Организатор не несет ответственности за какие-либо последствия ошибок Участника, включая (кроме
                                        всего прочего) понесенные последним затраты.
                                    </p>
                                    <p>10.4.Организатор обязан:</p>
                                    <p>
                                        10.4.1. Выдать призы Участникам, выполнившим условия Акции, предусмотренные в настоящих Правилах, в
                                        рамках общего количества призового фонда Акции, согласно настоящим Правилам.
                                    </p>
                                    <p>
                                        10.4.2. В случае досрочного прекращения или приостановления проведения Акции опубликовать об этом
                                        сообщение на Сайте.
                                    </p>
                                    <p>
                                        10.5. Данные, вводимые при регистрации/используемые при авторизации на Сайте, должны быть точными,
                                        корректными и актуальными (достоверными). Каждый Участник может зарегистрироваться на Сайте, в том
                                        числе, для участия в любой из активностей в рамках Акции, несколько раз.
                                    </p>
                                    <p>11. ПРОЧИЕ УСЛОВИЯ АКЦИИ</p>
                                    <p>
                                        11.1.Расходы на пользование Интернетом могут различаться у разных провайдеров и не зависят от
                                        Организатора/Оператора, поэтому Участнику необходимо заранее уточнить текущие цены у местного
                                        поставщика интернет-услуг.
                                    </p>
                                    <p>
                                        11.2.Организатор, а также уполномоченные им лица не несут ответственности за технические сбои в
                                        работе Интернет-провайдера, к которому подключен Участник.
                                    </p>
                                    <p>11.3.Результаты проведения Акции являются окончательными и не подлежат пересмотру.</p>
                                    <p>12. ПЕРСОНАЛЬНЫЕ ДАННЫЕ.</p>
                                    <p>
                                        12.1. В случаях и порядке, предусмотренных настоящими Правилами и Федеральным законом №152-ФЗ «О
                                        персональных данных» (далее – Закон), Организатор организует и осуществляет обработку персональных
                                        данных участников Акции, а также определяет цели обработки персональных данных участников Акции,
                                        состав персональных данных, подлежащих обработке, действия (операции), совершаемые с персональными
                                        данными участников Акции;
                                    </p>
                                    <p>
                                        12.2. Принимая участие в Акции, субъект персональных данных выражает своё согласие на обработку
                                        Организатором следующих персональных данных с целью связи с клиентом по доставке выигрыша а так же
                                        по дополнительным акциям этой рекламной кампании
                                    </p>
                                    <p>
                                        12.2.1. Уникальный идентификационный (ID) номер участника Акции и дата его создания, фамилия, имя,
                                        отчество, дата рождения, пол, номер телефона, адрес электронной почты, индекс, регион, район,
                                        населенный пункт, улица, номер дома, строение, корпус, квартира Отправка сообщений участникам Акции
                                        о выигрыше и отправке Приза (в случае выигрыша), отправка информационных сообщений, индивидуальное
                                        общение с Участниками Акции, ведение реестра лиц, участвующих в Акции и их идентификация в
                                        информационной системе персональных данных, обеспечение соблюдения законодательства;
                                    </p>
                                    <p>
                                        12.2.2. Сведения об условиях и дате предоставления согласия на обработку персональных данных,
                                        сведения об условиях и дате предоставления согласия на получение рекламных и информационных
                                        сообщений Обеспечение соблюдения требований законодательства Российской Федерации о персональных
                                        данных, а также законодательства Российской Федерации о рекламе;
                                    </p>
                                    <p>
                                        12.2.3. Сведения об истории участия в рекламных акциях, проводимых Организатором Индивидуальное
                                        общение с участниками завершившейся Акции, направление индивидуальных предложений об участии в
                                        последующих акциях Организатора;
                                    </p>
                                    <p>
                                        12.2.4. Сведения о выигранных участником Акции призах; Фамилия, имя, отчество, номер телефона, адрес
                                        электронной почты, паспортные данные Победителя (главная страница и страница со сведениями об адресе
                                        регистрации на территории Российской Федерации). Отправка сообщений участникам Акции о выигрыше
                                        Приза, отправка Приза участнику Акции (в случае выигрыша), исполнение обязанностей налогового агента
                                        в соответствии с действующим законодательством Российской Федерации;
                                    </p>
                                    <p>
                                        12.3. Участник Акции (субъект персональных данных) также выражает своё согласие на поручение
                                        обработки своих персональных данных, указанных в пунктах 12.2.1 – 12.2.6. настоящих Правил,
                                        Операторам в соответствии с пунктом 3 статьи 6 Федерального закона от 27.07.2006 №152-ФЗ «О
                                        персональных данных» в целях, указанных в пунктах 12.2.1 – 12.2.6. настоящих Правил.
                                    </p>
                                    <p>
                                        12.4. Организатор и Операторы обязаны обеспечить конфиденциальность персональных данных Участника
                                        Акции в установленном законом порядке.
                                    </p>
                                    <p>
                                        12.5. Согласие Участника на обработку его персональных данных действует до момента окончания срока
                                        Акции, указанного в Разделе 5 настоящих Правил.
                                    </p>
                                    <p>
                                        12.7. В случае непредставления Участником Акции сведений, указанных в пункте 12.2.1 настоящих
                                        Правил, в течение 3-х календарных дней с момента направления запроса Участнику Акции или в случае
                                        выявления факта указания недостоверных или неполных сведений при регистрации Участника Акции, либо в
                                        случае иных нарушений настоящих Правил и/или законодательства Российской Федерации Организатор
                                        вправе отменить регистрацию Участника, отказать в доступе на Сайт, отказать в выдаче приза и в
                                        дальнейшем участии каким-либо образом в Акции данному Участнику, а также всем лицам, которые
                                        использовали или будут использовать в будущем указанный таким Участником Акции при регистрации на
                                        Сайте номер мобильного телефона, адрес электронной почты или фамилию, имя и отчество. При этом
                                        Организатор обязан прекратить обработку персональных данных и обеспечить прекращение такой обработки
                                        Операторами в случае, если сохранение персональных данных более не требуется для целей обработки
                                        персональных данных, уничтожить персональные данные и обеспечить их уничтожение Операторами в срок,
                                        предусмотренный действующим законодательством, если Организатор не вправе осуществлять обработку
                                        персональных данных без согласия Участника Акции на основаниях, предусмотренных действующим
                                        законодательством.
                                    </p>
                                    <p>
                                        12.8. После окончания срока Акции, указанного в пункте 5.1. настоящих Правил, Организатор обязан
                                        прекратить обработку персональных данных и обеспечить прекращение такой обработки Операторами и, в
                                        случае если сохранение персональных данных более не требуется для целей обработки персональных
                                        данных, уничтожить персональные данные и обеспечить их уничтожение Операторами в срок,
                                        предусмотренный действующим законодательством, если Организатор не вправе осуществлять обработку
                                        персональных данных без согласия Участника Акции на основаниях, предусмотренных действующим
                                        законодательством (за исключением случаев установленных в п.12.9 – 12.11 настоящих Правил).
                                    </p>
                                    <p>
                                        12.9. После окончания срока Акции, указанного в пункте 5.1. настоящих Правил, Организатор вправе в
                                        течение пяти последующих лет обрабатывать следующие персональные данные: фамилия, имя, отчество,
                                        дата рождения, адрес места жительства, номер мобильного телефона, адрес электронной почты, история
                                        участия в акциях, проводимых Организатором и/или Операторами.
                                    </p>
                                    <p>
                                        12.10. Цели обработки персональных данных, указанных в п. 12.9 настоящих Правил, - отправка
                                        информационных сообщений, индивидуальное общение с Участниками Акции.
                                    </p>
                                    <p>
                                        12.11. В пункте 12.9 настоящих Правил под обработкой персональных данных понимаются следующие
                                        действия (операции) или совокупность действий (операций), совершаемых с использованием средств
                                        автоматизации или без использования таких средств с персональными данными, включая: предоставление,
                                        сбор, 8 запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение,
                                        использование, блокирование, удаление, уничтожение персональных данных.
                                    </p>
                                    <p>
                                        12.12. Участвуя в Акции, Участник соглашается с использованием Организатором персональных данных
                                        Участника (материалов о нем), а также разрешает Организатору брать у него рекламные интервью об
                                        участии в Акции (без сбора дополнительных персональных данных и без передачи уже собранных
                                        персональных данных третьим лицам) без уплаты за это какого-либо дополнительного вознаграждения.
                                    </p>
                                    <p>
                                        12.13. В отношении всех персональных данных, предоставленных Участниками в ходе Акции, Организатором
                                        будут соблюдаться режим их конфиденциальности и приниматься меры по обеспечению безопасности
                                        персональных данных в соответствии со статьей 19 Федерального закона РФ №152-ФЗ «О персональных
                                        данных». 12.13. Во всем, что не предусмотрено настоящими Правилами, Организатор, Операторы и
                                        Участники руководствуются применимым законодательством Российской Федерации.
                                    </p>
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
};

export default Oferta;
