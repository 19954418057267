import { useRoutes } from 'react-router-dom';

// routes
import MainRoutes from './main-routes';
import config from 'config';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
    return useRoutes([MainRoutes /* , ProductsRoutes, PrizesRoutes, PaymentsRoutes */ /* , AdminRoutes */], config.basename);
}
