import { buildPath } from '../../utils/path';
import { instanceUrl } from '../base';

const calendarPrefix = 'orders';

export async function createOrder(req) {
    const res = await fetch(buildPath(instanceUrl, calendarPrefix), {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json;charset=utf-8'
        },
        body: JSON.stringify(req)
    });

    return res;
}

export async function updateOrderItems(req) {
    const res = await fetch(buildPath(instanceUrl, 'prizes/set-gift'), {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json;charset=utf-8'
        },
        body: JSON.stringify(req)
    });

    return res;
}

export async function getByRetailCrmId(id) {
    const res = await fetch(buildPath(instanceUrl, calendarPrefix, id), {
        method: 'GET'
    });

    return res;
}

export async function checkCode(req) {
    const res = await fetch(buildPath(instanceUrl, 'prizes/check-code'), {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json;charset=utf-8'
        },
        body: JSON.stringify({ ...req })
    });

    return res;
}

export async function setCodeInfo(req) {
    const res = await fetch(buildPath(instanceUrl, 'prizes/set-code-info'), {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json;charset=utf-8'
        },
        body: JSON.stringify({ ...req })
    });

    return res;
}

export async function getNumberPP(req) {
    const res = await fetch(buildPath(instanceUrl, 'prizes/get-number-pp'), {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json;charset=utf-8'
        },
        body: JSON.stringify({ ...req })
    });

    return res;
}
