import React from 'react';
import { Outlet } from 'react-router';
import Prizes from 'pages/prizes';
import PrizesStart from 'pages/prizes-start';
import Oferta from 'pages/oferta';

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: <Outlet />,
    children: [
        {
            path: '/',
            element: <PrizesStart />,
            children: [
                {
                    path: '/:orderId',
                    element: <Prizes />
                }
            ]
        },
        {
            path: '/prizes/:orderId',
            element: <Prizes />
        },
        {
            path: '/oferta',
            element: <Oferta />
        }
    ]
};
/* const MainRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: '/',
            element: <Catalog />
        },
        {
            path: '/delivery',
            element: <DeliveryPage />
        },
        {
            path: '/about',
            element: <About />
        },
        {
            path: '/privacy-policy',
            element: <PrivacyPolicy />
        },
        {
            path: '/privacy-policy-full',
            element: <PrivacyPolicyFull />
        },
        {
            path: '/rules-scretch',
            element: <RulesScretch />
        },
        {
            path: '/oferta',
            element: <Oferta />
        },
        {
            path: '/personal-data-use',
            element: <PersonalDataUse />
        },
        {
            path: '/faq',
            element: <Faq />
        },
        {
            path: '/cart',
            element: <Cart />
        },
        {
            path: '/checkout',
            element: <Checkout />
        }
    ]
}; */

export default MainRoutes;
