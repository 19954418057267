import config from 'config';

export const prizes = [
    {
        id: 1,
        title: 'Деньги на карту',
        type: 'easy',
        imageUrl: `/media_new/money.png`,
        desc: 'Поздравляем, вы выиграли денежный приз - 200 рублей на карту, 🥳 В ближайшее время с Вами свяжется наш менеджер для уточнения получения подарка!',
        link: null,
        cardImage: `${config.storeUrl}/Novyi-proekt-11.png`,
        productId: 406,
        startNumber: 99999999999999,
        step: 99999999,
        priority: 1,
        hardNumber: [
            5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100, 105, 110, 115, 120, 125, 130, 135, 140, 145,
            150, 155, 160, 165, 170, 175, 180, 185, 190, 195, 200, 205, 210, 215, 220, 225, 230, 235, 240, 245, 250
        ]
    },
    {
        id: 2,
        title: 'Гайд',
        type: 'medium',
        imageUrl: `/media_new/guide.png`,
        desc: 'Поздравляем, вы открыли доступ к Гайду по стилю, 🥳 Скачать гайд можно ниже по ссылке!',
        link: 'https://lzet-gift.ru/guide.pdf',
        cardImage: `${config.storeUrl}/Novyi-proekt-12.png`,
        productId: 585,
        startNumber: 1,
        step: 1,
        priority: 1,
        hardNumber: null
    },
    {
        id: 3,
        title: 'Unknown',
        type: 'medium',
        imageUrl: `/media_new/guide.png`,
        desc: 'Unknown.',
        link: null,
        cardImage: `${config.storeUrl}/Novyi-proekt-12.png`,
        productId: 7741,
        startNumber: 4323534534523,
        step: 234523452345,
        priority: 1,
        hardNumber: null
    },
    {
        id: 4,
        title: 'Unknown',
        type: 'easy',
        imageUrl: `/media_new/guide.png`,
        desc: 'Unknown.',
        link: null,
        cardImage: `${config.storeUrl}/Novyi-proekt-11.png`,
        productId: 406,
        startNumber: 3234523452345,
        step: 5234523452345,
        priority: 1,
        hardNumber: null
    },
    {
        id: 5,
        title: 'Unknown',
        type: 'medium',
        imageUrl: `/media_new/guide.png`,
        desc: 'Unknown',
        link: null,
        cardImage: `${config.storeUrl}/Novyi-proekt-12.png`,
        productId: 585,
        startNumber: 345243523452345,
        step: 10023450000,
        priority: 1,
        hardNumber: null
    },
    {
        id: 6,
        title: 'Unknown',
        type: 'medium',
        imageUrl: `/media_new/guide.png`,
        desc: 'Unknown',
        link: null,
        cardImage: `${config.storeUrl}/Novyi-proekt-11.png`,
        productId: 407,
        startNumber: 1000234500000,
        step: 1000000000,
        priority: 1,
        hardNumber: null
    },
    {
        id: 7,
        title: 'Unknown',
        type: 'hard',
        imageUrl: `/media_new/guide.png`,
        desc: 'Unknown',
        link: null,
        cardImage: `${config.storeUrl}/Novyi-proekt-12.png`,
        productId: 'iphone',
        startNumber: 99023450000,
        step: 10000000,
        priority: 1,
        hardNumber: null
    },
    {
        id: 8,
        title: 'Unknown',
        type: 'hard',
        imageUrl: `/media_new/guide.png`,
        desc: 'Unknown',
        link: null,
        cardImage: `${config.storeUrl}/Novyi-proekt-9.png`,
        productId: '1000',
        startNumber: 99923452349999,
        step: 1000000,
        priority: 1,
        hardNumber: null
    },
    {
        id: 9,
        title: 'Unknown',
        type: 'easy',
        imageUrl: `/media_new/guide.png`,
        desc: 'Unknown',
        link: 'https://lk.investor-plus.com/register-member-free?type=millidge',
        cardImage: `${config.storeUrl}/Novyi-proekt-11.png`,
        productId: 'investor',
        startNumber: 123452345,
        step: 5234523452345,
        priority: 1,
        hardNumber: null
    },
    {
        id: 10,
        title: 'Денежный приз',
        type: 'medium',
        imageUrl: `/media_new/guide.png`,
        desc: 'Unknown',
        link: null,
        cardImage: `${config.storeUrl}/Novyi-proekt-11.png`,
        productId: '2000',
        startNumber: 999999234599999,
        step: 399999999,
        priority: 1,
        hardNumber: null
    },
    {
        id: 11,
        title: 'Денежный приз',
        type: 'medium',
        imageUrl: `/media_new/guide.png`,
        desc: 'Unknown',
        link: null,
        cardImage: `${config.storeUrl}/Novyi-proekt-12.png`,
        productId: '5000',
        startNumber: 99999999,
        step: 21111111111,
        priority: 1,
        hardNumber: null
    },
    {
        id: 12,
        title: 'Денежный приз',
        type: 'medium',
        imageUrl: `/media_new/guide.png`,
        desc: 'Unknown',
        link: null,
        cardImage: `${config.storeUrl}/Novyi-proekt-12.png`,
        productId: '5000',
        startNumber: 99999999,
        step: 21111111111,
        priority: 1,
        hardNumber: null
    }
];
