import { Button, TextField, InputAdornment, FormControl, Select, MenuItem, InputLabel, Input } from '@mui/material';
import { checkCode, setCodeInfo } from 'api/orders';
import Menu from 'components/menu';
import PositionedSnackbar from 'components/snackbar/snackbar';
import React, { useState } from 'react';
import { useAsyncFn } from 'react-use';
import { IMaskInput } from 'react-imask';
import Footer from 'components/footer';

const TextMaskCustom = React.forwardRef((props, ref) => {
    const { onChange, ...other } = props;
    return (
        <IMaskInput
            {...other}
            mask="+7 (#00) 000-00-00"
            maxLength={18}
            definitions={{
                '#': /[1-9]/
            }}
            inputRef={ref}
            onAccept={(value) => onChange(value)}
            overwrite
        />
    );
});

const PrizesStart = () => {
    const [winnerNumber, setCurrentNumber] = useState('');
    const [error, setError] = useState();
    const [isUse, setIsUse] = useState(null);

    const [phone, setPhone] = useState(null);
    const [city, setCity] = useState('');
    function decodeErrorMsg(error) {
        switch (error) {
            case 'NOT_FOUND':
                return 'Код не найден';
            case 'PRIZE_ALREADY_GIVE':
                return 'Приз по коду уже добавлен!';
            case 'COMMON_ERR':
                return 'Произошла ошибка!';
            default:
                return '';
        }
    }

    const [, handleSendCode] = useAsyncFn(async () => {
        const response = await checkCode({ code: `lzet-${winnerNumber}` });
        const orderJson = await response.json();
        setIsUse(orderJson.isUse);
        if (!orderJson.isExist) setError('NOT_FOUND');
        else if (orderJson.isExist && orderJson.isUse) {
            setError('PRIZE_ALREADY_GIVE');
        } else if (!orderJson.isExistPhone || !orderJson.isExistCity) {
            setError('WITHOUT_PERSONAL_INFO');
        } else {
            window.open(`/prizes/${winnerNumber}`, '_self');
        }
    }, [winnerNumber]);

    const [, handleSendInfo] = useAsyncFn(async () => {
        const response = await setCodeInfo({ code: `lzet-${winnerNumber}`, phone, city });
        const orderJson = await response.json();
        if (orderJson.isExist && orderJson.isUse) {
            setError('PRIZE_ALREADY_GIVE');
        } else if (orderJson.success) {
            window.open(`/prizes/${winnerNumber}`, '_self');
        } else {
            setError('COMMON_ERR');
        }
    }, [winnerNumber, phone, city]);

    return (
        <div className="rellbox overflow body on_thank" style={{ width: '100vw', height: '100vh' }}>
            <img src="/media_new/logo-01.svg" loading="lazy" width="100px" alt="" className="thank_bg_image" />
            <Menu />
            <div className="empty_header" />
            {/* <img
                src="/media/6221f25359f198e04c5c4d05_D0A1D0BBD0BED0B920120(1).png"
                loading="lazy"
                width="1920"
                alt=""
                className="thank_bg_image"
            /> */}
            <img src="/media/6221f145905f0017c860bfc5_Freesia20Flower.G05201.png" loading="lazy" alt="" className="thank_flover" />
            <div className="wrapper owerflow for_thank" style={{ display: 'flex', flexDirection: 'column', alignItems: 'stretch' }}>
                <section className="section about" style={{ marginTop: 0, marginBottom: 'auto' }}>
                    <div className="container">
                        <div className="thank_content">
                            <div className="thank_subtitle">Подарки</div>
                        </div>
                        <div className="w-form">
                            <div className="w-checkbox personal-code center">
                                <div className="center">Введите полученный персональный код!</div>
                                <TextField
                                    // label="Ваш персональный код"
                                    disabled={error === 'WITHOUT_PERSONAL_INFO'}
                                    id="standard-start-adornment"
                                    sx={{ m: 1, width: '25ch' }}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">lzet-</InputAdornment>
                                    }}
                                    variant="standard"
                                    onChange={(e) => setCurrentNumber(e.target.value)}
                                    value={winnerNumber}
                                    style={{ marginTop: '16px' }}
                                />
                                <Button
                                    disabled={!winnerNumber || winnerNumber.length < 9 || error === 'WITHOUT_PERSONAL_INFO'}
                                    style={{ marginTop: '16px', marginBottom: '16px' }}
                                    variant="contained"
                                    onClick={handleSendCode}
                                >
                                    Забрать
                                </Button>

                                {error && error !== 'WITHOUT_PERSONAL_INFO' && (
                                    <div className="container">
                                        <div className="thank_content">
                                            <div className="thank_desc">
                                                <div>
                                                    <strong style={{ color: 'red' }}>{`${decodeErrorMsg(error)}`}</strong>
                                                    <br />
                                                    <strong style={{ color: 'green' }}>{isUse}</strong>
                                                    <br />
                                                    <div
                                                        style={{
                                                            width: '100%',
                                                            margin: 'auto',
                                                            textAlign: 'center',
                                                            color: 'red',
                                                            marginTop: '24px'
                                                        }}
                                                    >
                                                        <span
                                                            data-content="prize_count_text"
                                                            className="card_info"
                                                            style={{ color: 'red' }}
                                                        >
                                                            В ближайшее время с Вами свяжется наш менеджер по вопросу получения подарка.
                                                            Если вдруг с Вами не связались в течение 3 рабочих дней, напишите нам в директ
                                                            @lzet.brand (где вы получили свой промокод)
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {error && error === 'WITHOUT_PERSONAL_INFO' && (
                                    <div className="container">
                                        <div className="thank_content">
                                            <div className="thank_desc">
                                                <div style={{ margin: '0 0' }}>
                                                    <FormControl sx={{ m: 1, width: '24ch' }}>
                                                        <Input
                                                            value={phone}
                                                            onChange={(value) => {
                                                                setPhone(value);
                                                            }}
                                                            name="textmask"
                                                            label="Номер телефона"
                                                            placeholder="Номер телефона"
                                                            id="formatted-text-mask-input"
                                                            inputComponent={TextMaskCustom}
                                                        />
                                                    </FormControl>
                                                    <FormControl sx={{ m: 1, width: '24ch' }}>
                                                        <InputLabel id="demo-simple-select-error-label">Ваш город</InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-error-label"
                                                            id="billing_country"
                                                            value={city}
                                                            name="billing_country"
                                                            //  onBlur={handleBlur}
                                                            onChange={(event) => setCity(event?.target.value)}
                                                            label="Ваш город"
                                                        >
                                                            <MenuItem value="MSK">Москва</MenuItem>
                                                            <MenuItem value="SPB">Санкт-Петербург</MenuItem>
                                                            <MenuItem value="KZN">Казань</MenuItem>
                                                            {/*  <MenuItem value="UFA">Уфа</MenuItem>
                                                            <MenuItem value="TVR">Тверь</MenuItem> */}
                                                            <MenuItem value="OTHER">Другой</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </div>
                                                <Button
                                                    disabled={city.length < 3 || phone.length < 18 || error !== 'WITHOUT_PERSONAL_INFO'}
                                                    style={{ marginTop: '16px', marginBottom: '16px' }}
                                                    variant="contained"
                                                    onClick={handleSendInfo}
                                                >
                                                    Продолжить
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <div style={{ position: 'absolute', bottom: 0, width: '100vw' }}>
                <Footer />
                <PositionedSnackbar />
            </div>
        </div>
    );
};

export default PrizesStart;
